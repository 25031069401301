import "lazysizes"
import "picturefill"

//アコーディオン
$('.js-menu__item__link').each(function () {
  $(this).on('click', function () {
    $("+.system__box,+.system__box--s,+.que__box", this).slideToggle();
    $(this).toggleClass("is-active")
    return false;
  });
});

//ヘッダーメニュー
$('.header__btn, .header__spMenu__item a').on('click', function () {
  $('html').toggleClass('scroll-prevent')
  if ($(".header__spMenu").hasClass('is-active')) {
    $(".header__btn").removeClass('is-active');
    $(".header__spMenu").removeClass('is-active');
  } else {
    $(".header__btn").addClass('is-active');
    $(".header__spMenu").addClass('is-active');
  }
})

// FAQ
$('.contentFaq__item__q').each(function() {
  $(this).on('click', function() {
    $('+.contentFaq__item__a', this).slideToggle();
    $(this).toggleClass('is-active');
    return false;
  });
});

// アニメーション
$(window).scroll(function () {
  var scroll = $(this).scrollTop();
  $('.line').css({'transform': `skewX(-25deg) translateY(-${scroll / 5}px)`});
  $('.line--sec').css({'transform': `skewX(-25deg) translateY(-${scroll / 10}px)`});
  $('.line--tert').css({'transform': `skewX(-25deg) translateY(-${scroll / 15}px)`});
  $('.contentAbout__img__main').css({'transform': `translateY(-${scroll / 10}px)`});
});

$('a[href^="#"]').click(function () {
  const speed = 500;
  const href = $(this).attr("href");
  const target = $(href == "#" || href == "" ? 'html' : href);
  const position = target.offset().top;
  $("html, body").animate({ scrollTop: position }, speed, "swing");
  return false;
});

var wowOffset = -1;
$(window).resize(function(){
	wowOffset = window.parent.screen.height / 5;
});
$(window).load(function(){
	if( wowOffset == -1 ) wowOffset = window.parent.screen.height / 5;
	new WOW({offset:wowOffset}).init();
});